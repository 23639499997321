.base .panel {
  padding: 3rem;
}

.base .bottom-panel{
  padding: 1rem 4rem;
  margin-top: 2rem;
  text-align:center;
}
.base .is-loading {
  opacity: 0.2;
}
