.category-title{
  border-top: 1px solid rgba(var(--light-grey-color), .5);
  margin-top: 3rem;
}
.flex {
  display: flex;
  flex-direction: row;
  text-align: left;
  justify-content: space-between;
}

.flex > :first-child {
  width: 50rem;
}

.flex > :last-child {
  display: flex;
  justify-content: space-between;
  width: 16rem;
}

.type {
  margin-bottom: 1.6rem;
}

@media screen and (max-width: 700px) {
  .settings-header {
    display: none;
  }

  .flex {
    flex-wrap: wrap;
  }

  .flex div:first-child {
    width: 100%;
  }

}
