.base {
  position:relative;
  text-align: center;
  margin-bottom: 2rem;
}

.heading {
  margin: 2rem 0 3rem 0;
}

.logo-container{
  width: 24rem;
  margin: 0 auto;
}

.logo-container svg{
  width: 24rem;
}

.base img {
  margin: 0 auto;
  max-width: 100%;
}

.base h1 {
  margin-top: 3rem;
  margin-bottom: 2rem;
  font-size: 2.4rem;
}

.base p {
  font-size: 1.6rem;
}

.base h1 + h3 {
  text-align: center;
  margin-bottom: 1rem;
}

button.back-button {
  position: absolute;
  top: 0;
  z-index: 10;
  left: -4rem;
  transition: 250ms;
  border: none;
}
button.back-button:hover{
  background: rgba(0,0,0,.05);
  border:none;
}

@media (max-width: 420px){
  button.back-button {
    left: -8%;
  }
}
