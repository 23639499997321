a.back-button,
button.back-button {
  top: 90px;
  left: 25px;
  display:flex;
  margin-right: 10px;
  position: absolute;
  z-index: 10;
}

a.back-button:hover svg,
button.back-button:hover svg{
  transform: scale(1.1);
}

a.back-button svg,
button.back-button svg {
  color: rgb(var(--primary-color));
  opacity: 0.9;
}

.base span{
  display:none;
  color: rgb(var(--primary-color));
}
.base:hover span{
  display: inline;
}
