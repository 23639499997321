.stepper {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.stepper li {
  background: #d8d8d8;
  border-radius: 8px;
  height: 16px;
  margin-left: 90px;
  position: relative;
  width: 16px;
}

.stepper li.clickable {
  cursor: pointer;
}

@media (min-width: 960px) {
  .stepper li {
    margin-left: 150px;
  }
}
.stepper li span {
  display: none;
}

.stepper.showLabel li span {
  color: rgba(51, 52, 86, 0.1);
  font-weight: 600;
  display: block;
  position: absolute;
  left: 50%;
  text-align: center;
  width: 100px;
  margin-left: -50px;
  top: 20px;
}

.stepper.showLabel li.active span {
  color: rgba(51, 52, 86, 0.5);
}

.stepper li::before {
  background: #d8d8d8;
  content: "";
  display: block;
  height: 1px;
  top: 7px;
  position: absolute;
  left: -90px;
  width: 90px;
}

@media (min-width: 960px) {
  .stepper li::before {
    left: -150px;
    width: 150px;
  }
}

.stepper li:first-child {
  margin-left: 0;
}

.stepper li:first-child::before {
  display: none;
}

.stepper li::after {
  background: #fff;
  border-radius: 2px;
  content: "";
  display: block;
  height: 4px;
  left: 6px;
  position: absolute;
  top: 6px;
  width: 4px;
}

.stepper li.active,
.stepper li.active::before {
  background: rgb(var(--primary-color));
}
