@font-face {
    font-family: 'Eina 03';
    src: url('./Eina03-BoldItalic.eot');
    src: url('./Eina03-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('./Eina03-BoldItalic.woff2') format('woff2'),
        url('./Eina03-BoldItalic.woff') format('woff'),
        url('./Eina03-BoldItalic.ttf') format('truetype'),
        url('./Eina03-BoldItalic.svg#Eina03-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Eina 03';
    src: url('./Eina03-LightItalic.eot');
    src: url('./Eina03-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('./Eina03-LightItalic.woff2') format('woff2'),
        url('./Eina03-LightItalic.woff') format('woff'),
        url('./Eina03-LightItalic.ttf') format('truetype'),
        url('./Eina03-LightItalic.svg#Eina03-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Eina 03';
    src: url('./Eina03-Light.eot');
    src: url('./Eina03-Light.eot?#iefix') format('embedded-opentype'),
        url('./Eina03-Light.woff2') format('woff2'),
        url('./Eina03-Light.woff') format('woff'),
        url('./Eina03-Light.ttf') format('truetype'),
        url('./Eina03-Light.svg#Eina03-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Eina 03';
    src: url('./Eina03-SemiBold.eot');
    src: url('./Eina03-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('./Eina03-SemiBold.woff2') format('woff2'),
        url('./Eina03-SemiBold.woff') format('woff'),
        url('./Eina03-SemiBold.ttf') format('truetype'),
        url('./Eina03-SemiBold.svg#Eina03-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Eina 03';
    src: url('./Eina03-Bold.eot');
    src: url('./Eina03-Bold.eot?#iefix') format('embedded-opentype'),
        url('./Eina03-Bold.woff2') format('woff2'),
        url('./Eina03-Bold.woff') format('woff'),
        url('./Eina03-Bold.ttf') format('truetype'),
        url('./Eina03-Bold.svg#Eina03-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Eina 03 RegularItalic';
    src: url('./Eina03-RegularItalic.eot');
    src: url('./Eina03-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('./Eina03-RegularItalic.woff2') format('woff2'),
        url('./Eina03-RegularItalic.woff') format('woff'),
        url('./Eina03-RegularItalic.ttf') format('truetype'),
        url('./Eina03-RegularItalic.svg#Eina03-RegularItalic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Eina 03';
    src: url('./Eina03-Regular.eot');
    src: url('./Eina03-Regular.eot?#iefix') format('embedded-opentype'),
        url('./Eina03-Regular.woff2') format('woff2'),
        url('./Eina03-Regular.woff') format('woff'),
        url('./Eina03-Regular.ttf') format('truetype'),
        url('./Eina03-Regular.svg#Eina03-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Eina 03';
    src: url('./Eina03-SemiboldItalic.eot');
    src: url('./Eina03-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
        url('./Eina03-SemiboldItalic.woff2') format('woff2'),
        url('./Eina03-SemiboldItalic.woff') format('woff'),
        url('./Eina03-SemiboldItalic.ttf') format('truetype'),
        url('./Eina03-SemiboldItalic.svg#Eina03-SemiboldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
}

