.confirmation {
  text-align: center;
}

.confirmation p {
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
}

.base footer{
  display: flex;
  margin: 1rem 0;
}

.base footer div {
  margin-left: auto;
}
.base a{
  margin-left: 1rem;
}
