.base {
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  background: white;
  position: absolute;
  right: 0;
  top: 64px;
  min-width: 300px;
  max-width: 100%;
  z-index: 3;
  height: auto;
  overflow:hidden;
  transition: height .7s step-end, opacity .7s, transform .2s;
  opacity:1;
  transform: translateY(0);
}
@media (max-width: 959px) {
  .base {
    width: 100%;
  }
}
.hidden {
  opacity: 0;
  height: 0;
  transform: translateY(-20px);
  transition: height .7s step-end, opacity .7s, transform .2s;
}
.group {
  border-bottom: 1px solid rgba(60,60,80, 0.15);
  padding: 0;
}
.group a, .group button {
  background: rgb(255,255,255);
  align-items: center;
  color: rgb(var(--dark-text-color));
  border: 0;
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 1.4rem;
  justify-content: flex-start;
  padding: 2rem 3rem;
  text-decoration: none;
}
.group a:hover, .group button:hover {
  background: rgb(var(--primary-color));
  color: rgb(var(--primary-color-text));
}
.group a span, .group button span {
  margin-left: 1rem;
}
.counter{
  display:flex;
  width:100%;
}

.counter::after {
  content: attr(data-count);
  align-items: center;
  background: #FF4E4E;
  margin-left: auto;
  border-radius: 9px;
  font-size: 1.2rem;
  color: white;
  display: flex;
  height: 18px;
  justify-content: center;
  width: 18px;
}
