.base {
  margin-top: 2rem;
}

.title strong {
  color: rgb(var(--primary-color));
}

.plans {
  margin-bottom: 6rem;
}

.pricepanel {
  background: #f5f6f8;
  height: 30px;
  margin-top: 10px;
  padding-top: 10px;
  width: 100%;
}

.subscription {
  padding-bottom: 2rem;
}

.prepaid {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.prepaid button {
  white-space: nowrap;
  align-self: flex-end;
}

.base footer {
  display: flex;
  justify-content: flex-end;
  margin: 4rem 0 0;
}

.usps {
  text-align: left;
  list-style: none;
  padding-left: 0;
}

.usps li {
  display: flex;
  align-items: center;
  margin: .5rem 0;
}

.usps li:before {
  content: '✓';
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid rgb(var(--primary-color));
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 100%;
  background-color: rgb(var(--primary-color));
  color: rgb(var(--primary-color-text));
  padding: 0 .5rem;
  overflow: hidden;
  margin-right: 1rem;
}

.duration-switch {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3.2rem;
}

.duration-switch > strong {
  margin-bottom: 1rem;
  font-size: 1.4rem;
  line-height: 2.4rem;
}

.options {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.option {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  width: 11.6rem;
  height: 6.6rem;
}

.option strong {
  font-size: 1.4rem;
  line-height: 2.4rem;
}

.option span {
  font-size: 1rem;
  line-height: 1.2rem;
}

.option {
  border-radius: 0;
}

.option:first-child {
  border-radius: var(--border-radius) 0 0 var(--border-radius);
}

.option:last-child {
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
}

@media (min-width: 960px) {
  .prepaid {
    width: 70%;
  }
}
