.checkoutLayout {
  padding: 25px 0;
}

.checkoutLayout h1 {
  margin-top: 4rem;
  margin-bottom: 1.4rem;
}

.cancelLink {
  align-items: center;
  display: inline-flex;
}

.checkoutLayout :global .panel-component {
  border-radius: 0;
  box-shadow: none;
  padding: 18px;
}

@media (min-width: 960px) {
  .checkoutLayout {
    padding: 25px 0;
  }

  .checkoutLayout :global .panel-component {
    border-radius: 4px;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.05);
    margin-left: 0;
    margin-right: 0;
    padding: 4rem;
    gap: 2rem;
  }
}
