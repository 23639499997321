.base {
  background: none;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  border-radius: var(--border-radius);
  border: 1px solid rgba(var(--grey-color), 0.3);
  padding: 2.5rem 2.5rem 0.5rem 2.5rem;
  display: flex;
  flex-flow: column nowrap;
  transition: all .2s;
  align-items: center;
}

.base:focus {
  box-shadow: 0 0 1px 2px rgba(var(--focus-color, 255, 78, 78), 1);
}

.title {
  justify-content: center;
  display: flex;
  margin-bottom: 1rem;

  font-size: 1.8rem;
  line-height: 2.8rem;
}

.duration {
  font-size: 1.2rem;
  line-height: 2rem;
  color:rgba(var(--grey-color), 1);
  text-align: center;
}

.spacer {
  flex-grow: 1;
}

.submit-button {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
}

.base .price {
  margin: 3.5rem 0;
  font-size: 3.2rem;
  line-height: 5rem;
  text-align: center;
}

.base .icon {
  height: auto;
  text-align: center;
  margin-bottom: 4rem;
  min-height: 13rem;
}

.base .icon img {
  max-width: 10rem;
}


.features {
  text-align: left;
  width: 100%;
  margin-bottom: 2rem;
}
.features > span {
  display: block;
  margin-bottom: 1rem;
}
.features .feature {
  display: flex;
  align-content: center;
  text-align: left;
  margin: .4rem 0;
}

.features .feature svg {
  color:rgba(var(--primary-color), 1);
  margin-right: 1rem;
}

.warning {
  color:rgba(var(--primary-color), 1);
  width: 100%;
  font-weight: bold;
  text-align: left;
  margin-bottom: 1.9rem;
}

/* SELECTED */

.base.selected {
  border: 2px solid rgba(var(--primary-color), 1);
  transform: scale(1.05);
}

/* DISABLED */

.base.disabled {
  color: rgba(var(--light-grey-color));
  opacity: 0.4;
}

.base.disabled .badge {
  background: rgb(var(--light-grey-color)) !important;
}

.base.disabled button {
  color: rgb(var(--light-grey-color));
}
