@import url("./Assets/Fonts/eina/eina.css");

:root{
  --info-color: 77,124,254;
  --info-color-text: var(--dark-text-color);
  --error-color: 255,78,78;
  --error-color-text: var(--dark-text-color);
  --success-color: 69,208,154;
  --success-color-text: var(--dark-text-color);
  --warning-color: 255,204,65;
  --warning-color-text: var(--dark-text-color);
}

html {
  font-size: 10px;
}

body {
  background-color: #f2f3f6;
  color: rgb(var(--dark-text-color));
  font-family: var(--body-font-family, var(--font-family, 'Eina 03')), sans-serif;
  margin: 0;
}

div#root {
  margin-top: 12rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--body-font-family, var(--font-family, "Eina 03")), "Eina 03",
  sans-serif;
  font-weight: 600;
  line-height: 1.2;
}

h1 {
  font-family: var(--heading-font-family, var(--font-family, "Eina 03")),
  "Eina 03", sans-serif;
  font-size: 3rem;
}
h2 {
  font-size: 2.2rem;
}
h3 {
  font-size: 1.6rem;
}
h4 {
  font-size: 1.4rem;
}
h6 {
  font-size: 1.2rem;
}

p, div {
  font-size: 1.4rem;
}

a {
  text-decoration: none;
  color: rgb(var(--primary-color));
  border-bottom: 1px solid transparent;
}
a:hover {
  border-bottom-color: rgb(var(--primary-color));
}

label {
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 5px;
  width: 100%;
  cursor: pointer;
  transition: all .3s;
}

strong {
  font-weight: 600;
}

ul, ol {
  font-size: 1.4rem;
}
