.base {
  font-size: 1rem;
  margin: 1rem 0 5rem;
  text-align: center;
}

.active {
  font-weight: bold;
}

.base span {
  margin: 1rem;
}
