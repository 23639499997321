.base {
  padding: 3rem;
}
.base h1 span {
  white-space: nowrap;
}

.methods {
  margin-top: 2rem;
}

.methods .method {
  position:relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 1.1rem 1.5rem;
  border: 1px solid #eee;
  border-radius: var(--border-radius);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.10);
  transition: 250ms;
  margin: 2.25rem 0;
  color: #494949;
  text-decoration: none;
}
.methods .method:hover {
  border-bottom-color: #eee;
}

.methods .label {
  display: flex;
  width:100%;
  font-size: 1.5rem;
  font-weight: bold;
}
.methods button.tooltip {
  width: 20px;
  height: 20px;
  margin-left: auto;
  color: rgb(var(--primary-color));
}

.methods .method:hover .label{
  text-decoration: underline;
}

.methods .description {
  margin-top: .9rem;
  font-size: 1.2rem;
  display:block;
}

.methods .description a {
  color: rgb(var(--info-color));
}

.methods .description a:hover {
  border-bottom-color: rgb(var(--info-color));
}

.methods .extraMargin {
  margin-left: 1.5rem;
}
.methods a img {
  max-width: 2rem;
  max-height: 2rem;
  border-radius: var(--border-radius);
}
