.base {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  border: none;
  padding: 0;
  margin: 2rem 0 0 0;
}

.base legend{
  font-weight: 600;
  margin-bottom: .6rem;
  font-size: 1.4rem;
}
