div.panel {
  margin-top: 2rem;
  overflow:visible;
}

.panel hr {
  border: none;
  border-top: 1px solid #efeff6;
}

.birthDateInput label {
  display: block;
  line-height: 20px;
  margin-bottom: 5px;
}

.birthDateInput > div {
  white-space: nowrap;
}

.footer {
  align-items: flex-start;
  display: flex;
  margin-top: 1rem;
  flex-wrap: nowrap;
}


.footerButtons {
  display: flex;
  margin-left: auto;
}

.footerButtons a,
.footerButtons button {
  margin-right: 1rem;
  white-space: nowrap;
}

.panel :global(.dropdown-base) ul {
  position:absolute;
  top: 100%;
}
