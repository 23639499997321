.base {
  padding-top: 60px;
  text-align: center;
}
.absolute {
  position:absolute;
  z-index: 1000;
}
.center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
