/* Skills and Interests */
.base {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
}


.heading {
  margin-top: 3rem;
  margin-bottom: 1rem;
  border-top: 1px solid rgba(var(--light-grey-color), 0.4);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.heading h3 {
  margin-top: 3rem;
  margin-bottom: 1rem;
}



