div.base {
  padding: 3rem;
}

.reset-button {
  margin-top: 2rem;
}

@media (max-width: 420px) {
  div.base {
    padding: 2rem;
  }
}
