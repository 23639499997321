.panel {
  display: flex;
  flex-flow: column;
}

.panel main {
  flex: 1;
}

@media (min-width: 960px) {
  .panel {
    flex-flow: row;
  }
}

.paymentMethods.active .paymentMethod:not(.active) {
  background: #c2c2cc !important;
}

.paymentMethod {
  align-items: center;
  border-radius: 4px;
  border: none;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 8px 0;
  position: relative;
  text-align: center;
  width: 100%;
}

.paymentMethod img {
  left: 5px;
  position: absolute;
  top: 5px;
}

.paymentMethod span {
  font-family: "Eina 03", sans-serif;
}

.panel aside {
  border-bottom: 1px solid #f2f3f6;
  padding: 12px 18px;
}

@media (min-width: 960px) {
  .panel aside {
    border-bottom: none;
    border-left: 1px solid #f2f3f6;
    padding: 0;
    width: 32rem;
  }
}

.panel aside section {
  border-bottom: 1px solid #f2f3f6;
  padding: 10px 16px !important;
}

.panel aside section:last-child {
  border-bottom: none;
}

.panel aside section.disabled {
  opacity: 0.2;
}

.panel aside h3,
.panel aside h4 {
  margin: 0;
}

.panel aside .editOrder {
  margin: 12px 0;
}

.panel aside a {
  border-bottom: none;
  color: rgba(51, 52, 86, 0.6);
  font-size: 12px;
  font-weight: 600;
  text-decoration: underline;
}

.gray {
  background: #f2f3f6;
}

.yourOrder {
  color: rgba(51, 52, 86, 0.3);
  margin: 8px 0;
  text-transform: uppercase;
}

.orderLine {
  padding-left: 16px;
}

.orderLine .productTitle {
  font-size: 1.4rem;
  font-weight: bold;
  position: relative;
}

.orderLine .productTitle:before {
  background: rgb(var(--primary-color));
  border-radius: 5px;
  content: "";
  height: 6px;
  position: absolute;
  width: 6px;
  left: -16px;
  top: 8px;
}
.productPrice {
  color: rgba(var(--dark-text-color),.5);
  font-size: 1rem;
}
.totalPriceVat {
  color: rgba(var(--dark-text-color),.5);
  font-size: 1rem;
}
.orderLine p {
  margin: 0;
}

.total {
  display: flex;
  justify-content: space-between;
}

.footer {
  align-items: flex-start;
  display: flex;
  margin-top: 2rem;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.terms {
  order: 1;
  text-align: left;
}
.terms p:first-child {
  margin-top: 0;
}

.terms ul {
  text-align:left;
  list-style:none;
}

.terms li {
  margin-bottom: .5rem;
}

.terms li:before {
  content: '✓';
  display:inline-flex;
  border: 1px solid rgb(var(--primary-color));
  font-size: 0.9rem;
  border-radius: var(--border-radius);
  background-color: rgb(var(--primary-color));
  color: rgb(var(--primary-color-text));
  justify-content:center;
  width: 1rem;
  height: 1rem;
  align-items: center;
  overflow: hidden;
  margin-right: 1rem;
}

.footerButtons {
  order: 2;
  display: flex;
}

.footerButtons a,
.footerButtons button {
  margin-right: auto;
  margin-left: 1rem;
  white-space: nowrap;
}

@media (max-width: 960px) {
  .footer {
    flex-wrap: wrap;
  }
  .footer > * {
    margin: 1rem auto;
  }

  .footer p,
  .footer ul {
    margin: 0 auto;
    text-align: center;
    max-width: 500px;
  }
  .terms ul {
    text-align:left;
    list-style:none;
  }

  .terms li:before {
    content: '✓';
  }

  .terms {
    order: 2;
    width: 100%;
  }

  .footerButtons {
    order: 1;
  }

  .footerButtons a,
  .footerButtons button {
    width: 100%;
  }
}

.prepaid-wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 4rem;
  gap: 1rem;
}

.btn-wrapper {
  display: flex;
  align-items: flex-end;
}
