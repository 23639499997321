.account .banner {
  margin-top: 2rem;
}

.account .cancel {
  margin-left: 1rem;
}

.select-coach {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.select-coach-actions {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}
