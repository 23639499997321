.base {
  position:relative;
  max-width: 100%;
}
.flash {
  position:fixed;
  right: 2rem;
  bottom: 2rem;
  z-index: 20;
  background: #fff;
}
.small{
  width: 54rem;
  margin: 12vh auto;
}
