.base {
}

.base > div {
  box-sizing: border-box;
  height: 100%;
  border-radius: var(--border-radius);
  border: 1px solid rgba(var(--grey-color), 0.3);
  padding: 1rem;
  display: flex;
  flex-flow: column nowrap;
  transition: all .2s;
}

.title {
  align-content: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.spacer {
  flex-grow: 1;
}

.submit-button {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
}

.base .price {
  margin: 0 0 2rem 0;
  font-size: 3rem;
}

.base .icon {
  height: auto;
  text-align: center;
  margin-bottom: 2rem;
}

.base .icon img {
  max-width: 10rem;
}

.base button {
  color: rgb(var(--primary-color));
  margin-top: 1rem;
  padding: 1rem;
  justify-content: center;
  height: 4rem;
  width: 100%;
  margin-bottom: 2rem;
}

/* SELECTED */

.base.selected > div {
  border: 2px solid rgba(var(--primary-color), 1);
  padding: calc(1rem - 1px);
  transform: scale(1.05);
}

.base.selected button {
  color: white;
}

/* DISABLED */

.base.disabled {
  color: rgba(var(--light-grey-color));
  opacity: 0.4;
}

.base.disabled .badge {
  background: rgb(var(--light-grey-color)) !important;
}

.base.disabled button {
  color: rgb(var(--light-grey-color));
}
