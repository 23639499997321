
.subscription {
  border: 1px solid #E5E5E5;
  border-radius: var(--border-radius);
  margin-bottom: 30px;
  padding: 16px;
}

.subscription h3 {
  color: rgb(var(--primary-color));
  margin: 0;
}

.subscription h4 {
  margin: 0.5rem 0;
  padding-bottom: 0.5rem;
}

.subscription p {
  margin: 0 0 10px;
}

@media (min-width: 960px) {
  .subscription p {
    margin-bottom: 0;
  }
}

@media (max-width: 720px){
  .hidden-on-small {
    display: none;
  }
}

.subscription em {
  color: #E6AA53;
  font-style: normal;
  font-weight: 700;
}

.time {
  opacity: 0.6;
}
