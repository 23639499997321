.panel {
  padding: 3rem;
  overflow: visible;
}

.terms-checkbox {
  position: relative;
  display:flex;
  align-items: center;
  background-color: #fff7d1;
  gap:3rem;
}

.terms-checkbox input {
  margin-left: 1rem;
}

.terms-checkbox label {
  padding: 1rem 0;
  margin: 0;
  border-radius: var(--border-radius);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.hint {
  font-size: 1.2rem;
}

.bottom-panel {
  margin-top: 2rem;
  text-align: center;
}

.base .panel .entityDropdown,
.base .panel .entityDropdown button,
.base .panel .entityDropdown ul {
  width: 100% !important;
}

.base .panel .entityDropdown {
  margin-bottom: 2rem;
}
