.base {
  position: relative;
  min-height: 8rem;
}

.statusWrap {
  display: inline-block;
  position: absolute;
  right: 1rem;
  top: 3.5rem;
  z-index: 2;
}

.indicator {
  height: 1rem;
  width: 1rem;
}

.hidden {
  /*opacity: 0;*/
  transition: opacity .2s ease 10s;
}

.error {
  color: rgb(var(--error-color));
  font-size: 1rem;
}
