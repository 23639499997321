.base {
  display: inline-flex;
  border-radius: var(--border-radius-large, var(--border-radius));
  background-color: rgb(var(--primary-color));
  color: rgb(var(--primary-color-text));
  align-items: center;
  padding: 3px 6px;
  font-size: .8rem;
  text-transform: uppercase;
}
