.base .menu {
  padding: 0;
  overflow: hidden;
}

.base nav {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.base nav a {
  display: inline-flex;
  align-items: center;
  color: #333456;
  padding: 1rem 2rem;
  border-bottom: 2px solid transparent;
}

.base nav a span {
  margin-left: .5rem;
}

.base nav :global(.active) {
  color: rgb(var(--primary-color));
  border-bottom-color: rgb(var(--primary-color));
  font-weight: bold;
}

.base .panel {
  margin-top: 2rem;
  margin-bottom: 5rem;
  padding: 4rem;
  overflow: visible;
}
