.base {
  align-items: center;
  color: rgb(var(--brand-color-text));
  display: flex;
  height: 6.4rem;
  justify-content: space-between;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 998;
  transition: 1s;
}

.base.mobile-scroll {
  height: 4.4rem;
}

.background {
  background: rgb(var(--brand-color));
  height: 100%;
  opacity: 1;
  position: absolute;
  transition: 1s;
  width: 100%;
  top: 0;
  z-index: -1;
}

.mobile-scroll > .background {
  opacity: 0;
}

.logo {
  display: flex;
  height: 100%;
}

.ie-logo {
  margin-right: auto;
}

.logo-button.loading {
  padding: 0;
}

a.logo-button {
  align-items: center;
  background-color: #fff;
  border-radius: 0 6.4rem 6.4rem 0;
  color: rgb(var(--brand-color));
  cursor: pointer;
  display: flex;
  height: 100%;
  padding: 0 2rem;
  box-sizing: border-box;
  transition: all .2s;
  min-width: 140px;
  border-color: transparent;
}
a.logo-button img, a.logo-button svg {
  height: 80%;
  margin: 0 auto;
  opacity: 1;
}

.header-menu button, .header-menu a {
  text-decoration: none;
  color: rgb(var(--brand-color-text));
  margin: 0 10px;
}
.header-menu-login > .header-menu-button {
  border-radius: var(--border-radius-large, var(--border-radius));
  margin: 0 10px;
}

.header-menu-login > .header-menu-button > a > span {
  text-decoration: none;
  color: #6760FA;
  margin: 0 10px;
}

.base .loginButton{
  color: rgb(var(--brand-color-text));
}

.title {
  flex-grow: 1;
  font-weight: 600;
  font-size: 2rem;
  line-height: 1.2;
  overflow: hidden;
  padding: 0 1rem;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 1;
  transition: 1s;
}

@media (max-width: 900px){
  .title{
    font-size: 1.7rem;
  }
}
@media (max-width: 340px){
  .title{
    display:none;
  }
}
.mobile-scroll > .title,
.mobile-scroll > .logo {
  opacity: 0;
}

.account {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-right: 2rem;
  opacity: 1;
  transition: 1s;
}
.mobile-scroll > .account {
  opacity: 0;
}

.accountLoading {
  opacity: 0;
}

.base .submenu-button {
  color: rgb(var(--brand-color-text));
}

.base .submenu-button svg {
  width: 21px;
  height: 21px;
  margin-left: 2rem;
}

@media(max-width: 430px) {
  .base .text {
    display: none;
  }
}
